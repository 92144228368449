import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import './scss/style.scss';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Page404 = React.lazy(() => import('./views/pages/page404/Page404.js'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500.js'));
const PDFViewer = React.lazy(() => import('./views/consultations/ConsultationPDF'));

class App extends Component {

  render() {
    return (
      <HashRouter>
          <React.Suspense fallback={loading}>
            <Switch>
              <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
              <Route path="/pdf/:id" name="PDFViewer" render={props => <PDFViewer {...props}/>} />
              <Route path="/" name="Home" render={props => <TheLayout {...props}/>} />
            </Switch>
            <ToastContainer position='bottom-left' />
          </React.Suspense>
      </HashRouter>
    );
  }
}

export default App;
