import Cookies from "js-cookie";
import { createStore, applyMiddleware } from 'redux'
import { thunk } from 'redux-thunk'

export const FETCH_USER_REQUEST = "playbook/user/REQUEST";
export const FETCH_USER_INVALIDATE = "playbook/user/INVALIDATE";
export const FETCH_USER_FAILURE = "playbook/user/FAILURE";
export const FETCH_USER_RECEIVE = "playbook/user/RECEIVE";

const initialState = {
  sidebarShow: 'responsive',
  user: {
    isInvalidated: true,
    isFetching: false,
    username: "",
    firstname: "",
    lastname: "",
    email: "",
    gender_iso5218: 1,
    country: "",
    roles: [],
    loggedInAt: null,
  }
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return {...state, ...rest }
    case FETCH_USER_INVALIDATE:
      return {
        ...initialState,
      };
    case FETCH_USER_FAILURE:
      return {
        ...state,
        user: {
          ...state.user,
          isFetching: false,
        }
      };
    case FETCH_USER_REQUEST:
      return {
        ...state,
        user: {
          ...state.user,
          isFetching: true,
          isInvalidated: false,
        }
      };
    case FETCH_USER_RECEIVE:
      return {
        ...state,
        user: {
          ...state.user,
          isFetching: false,
          isInvalidated: false,
          username: rest.username,
          realname: rest.realname,
          firstname: rest.firstname,
          lastname: rest.lastname,
          email: rest.email,
          gender_iso5218: rest.gender_iso5218,
          country: rest.country,
          roles: rest.roles,
          loggedInAt: Date.now ? Date.now() : new Date().getTime(),
        }
      };  
    default:
      return state
  }
}

//Action creators
export const requestUser = () => ({
  type: FETCH_USER_REQUEST,
});

export const invalidateUser = () => ({
  type: FETCH_USER_INVALIDATE,
});

export const fetchUserFailure = () => ({
  type: FETCH_USER_FAILURE,
});

export const writeUserData = (user) => ({
  type: FETCH_USER_RECEIVE,
  username: user.username,
  realname: user.realname,
  firstname: user.firstname,
  lastname: user.lastname,
  email: user.email,
  gender_iso5218: user.gender_iso5218,
  country: user.country,
  roles: user.roles,
});

export const shouldFetchUserData = (state) =>
  (!state.username && !state.email && !state.isFetching) ||
  Cookies.get("access_token") === "";

export const fetchUserDataIfNeeded = () => (dispatch, getState) => {
  if (Cookies.get("access_token") === undefined) {
    dispatch(invalidateUser());
  } else if (shouldFetchUserData(getState().user)) {
    return dispatch(fetchUserData());
  }
};

//Async action creators
export const fetchUserData = () => (dispatch, getState) => {
  dispatch(requestUser());
  let apiUrl = `${process.env.REACT_APP_API_URL}api/mybje/user`; //eslint-disable-line no-undef
  let xhr = new XMLHttpRequest();
  xhr.addEventListener("readystatechange", function () {
    if (this.readyState === 4) {
      dispatch(writeUserData(JSON.parse(this.responseText)));
    } else {
      dispatch(fetchUserFailure());
    }
  });

  xhr.open("GET", apiUrl);
  xhr.setRequestHeader(
    "Authorization",
    `Bearer ${Cookies.get("access_token")}`
  );
  xhr.send();
};

//Async action creators
export const fetchUserLogout = () => {
  Cookies.remove("access_token");
  Cookies.remove("refresh_token");
  return {
    type: FETCH_USER_INVALIDATE,
  };
};

//Selectors
export const getUser = (state) => state.user;

export const isLoggedIn = (state) => 
  state.user.username !== "" && Cookies.get("access_token") !== undefined;

export const isDashboardUser = (state) => {
  if (state?.user?.email && /@qmarketing.de\s*$/.test(state.user.email.toLowerCase())) {
    return true;
  }

  return state.user?.roles
    && Array.isArray(state.user.roles)
    && state.user.roles?.find(el => el === "ROLE_SSO_ADM_PLAYBOOK_ADMIN")
      ? true
      : false;
}

export const fetchJwtCookie = (username, password) => {
  let xhr = new XMLHttpRequest();
  return new Promise((resolve) => {
    xhr.open(
      "POST",
      `${process.env.REACT_APP_MYBUILDINGSAPI}user/access-token`,
      true
    );
    xhr.setRequestHeader(
      "Authorization",
      `Basic ${btoa(`${username}:${password}`)}`
    );
    xhr.onload = function () {
      if (this.status === 201) {
        Cookies.set("access_token", "xhr.responseText", { expires: 30 });
        resolve(true);
      } else {
        Cookies.remove("access_token");
        resolve(false);
      }
    };
    xhr.send();
  });
};

export const ssoAuth = (code) => {
  let xhr = new XMLHttpRequest();
  return new Promise((resolve) => {
    let data = new FormData();
    data.append("client_id", process.env.REACT_APP_SSOCLIENTID);
    data.append("client_secret", process.env.REACT_APP_SSOCLIENTSECRET);
    data.append("grant_type", "authorization_code");
    data.append("code", code);
    data.append("redirect_uri", window.location.origin);
    xhr.open("POST", `${process.env.REACT_APP_SSOURL}token`, true);
    xhr.addEventListener("readystatechange", function () {
      if (this.readyState === 4) {
        if (this.status === 200) {
          const response = JSON.parse(xhr.responseText);
          Cookies.set("access_token", response["access_token"], {
            expires: 30,
          });
          Cookies.set("refresh_token", response["refresh_token"], {
            expires: 90,
          });
          resolve(true);
        } else {
          Cookies.remove("access_token");
          Cookies.remove("refresh_token");
          resolve(false);
        }
      }
    });
    xhr.send(data);
  });
};

export const ssoRefresh = () => {
  let xhr = new XMLHttpRequest();
  return new Promise((resolve) => {
    let data = new FormData();
    data.append("client_id", process.env.REACT_APP_SSOCLIENTID);
    data.append("client_secret", process.env.REACT_APP_SSOCLIENTSECRET);
    data.append("grant_type", "refresh_token");
    data.append("refresh_token", Cookies.get("refresh_token"));
    data.append("redirect_uri", window.location.origin);
    xhr.open("POST", `${process.env.REACT_APP_SSOURL}token`, true);
    xhr.addEventListener("readystatechange", function () {
      if (this.readyState === 4) {
        if (this.status === 200) {
          const response = JSON.parse(xhr.responseText);
          Cookies.set("access_token", response["access_token"], {
            expires: 30,
          });
          Cookies.set("refresh_token", response["refresh_token"], {
            expires: 90,
          });
          resolve(response["access_token"]);
        } else {
          Cookies.remove("access_token");
          Cookies.remove("refresh_token");
          resolve(false);
        }
      }
    });
    xhr.send(data);
  });
};


const store = createStore(changeState, initialState, applyMiddleware(thunk))
export default store
